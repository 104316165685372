import { getDefaultRoute } from '@local/central-portal-core/dist/src/routing/central/routing';
import {
    setBaseApiEndpoint,
    setBaseEndpoint,
} from '@local/central-portal-core/dist/state-domains/utils/baseApiEndpoint';
import { setLoggingTag } from '@local/error-logging/dist/src/errorLogging';
import type { CentralInstance } from '@local/login';
import {
    LOGIN_ERROR_ROUTE,
    getCentralInstances,
    setSelectedInstanceStateForTab,
} from '@local/login';
import type { Location } from 'react-router';
import { Navigate, useLocation, useParams } from 'react-router';

import { getSelectedInstance } from '../utils/selectedInstance';

export interface OrgInstanceRouteGuardProps {
    children: JSX.Element;
}

/**
 * Route guard that ensures that the users org and instance from the url match the org and instance
 * that they are currently signed into.
 * The url is the source of truth and we only fallback to the instance stored in local/session
 * storage if the url doesn't contain an instance uuid. Our L7 redirects should ensure that we
 * always receive correctly formatted urls. We should only get an invalid url if the user has typed
 * it themselves, and are already logged in.
 */
export const OrgInstanceRouteGuard = ({ children }: OrgInstanceRouteGuardProps) => {
    const params = useParams();
    const location = useLocation();

    // Check that we have access to the list of the users Central instances in local storage.
    // We're relying on these to do the rest of the calculations in our route guard.
    const centralInstances = getCentralInstances();
    if (!centralInstances || !centralInstances.servers.length) {
        return <Navigate to={`../${LOGIN_ERROR_ROUTE}`} replace />;
    }

    // Since react-router-dom v6 no longer has parameter type matching, the "instance uuid" may
    // just be the first part of the path.
    let { instanceUuid } = params;

    if (urlDoesNotContainInstanceUuid(instanceUuid)) {
        const instanceFromStorage = getSelectedInstance();
        if (!instanceFromStorage) {
            // The user doesn't have an instance persisted in session/local storage that matches
            // the list of instances they have access to.
            return <Navigate to={`../${LOGIN_ERROR_ROUTE}?error=no_access`} replace />;
        }

        // While we could continue with the instance from storage, lets redirect them to a url with the correct
        // instanceUuid first.
        instanceUuid = instanceFromStorage.instanceUUID;

        const redirectUrl = buildRedirectUrl(location, instanceUuid);
        return <Navigate to={redirectUrl} replace />;
    }

    // Check that the user has access to the instance. Get Central Instances is the list of instances they have access
    // to, returned from IDP. This isn't an authoritative check, which will be covered when the license token is retrieved,
    // but is a good check for whether we need to return them to the login page.
    const centralInstance: CentralInstance | undefined = centralInstances.servers.find(
        (server: CentralInstance) => server.instanceUUID === instanceUuid,
    );
    if (!centralInstance) {
        return <Navigate to={`../${LOGIN_ERROR_ROUTE}?error=no_access`} replace />;
    }

    setSelectedInstanceStateForTab(centralInstance.host);
    setBaseApiEndpoint(`https://${centralInstance.host}/central`);
    setBaseEndpoint(`https://${centralInstance.host}`);
    setLoggingTag('CentralInstance', centralInstance.host);

    return children;
};

function urlDoesNotContainInstanceUuid(instanceUuid?: string) {
    return !instanceUuid || !isUuid(instanceUuid);
}

function isUuid(testString: string) {
    return /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
        testString,
    );
}

function buildRedirectUrl(location: Location, selectedInstanceUuid: string) {
    let { pathname } = location;
    if ([`/${selectedInstanceUuid}/`, '/', ''].includes(location.pathname)) {
        pathname = getDefaultRoute();
    }
    if (pathname.includes(selectedInstanceUuid)) {
        return pathname;
    }
    if (pathname.startsWith('/')) {
        pathname = pathname.substring(1);
    }
    return `../../../../../../${selectedInstanceUuid}/${pathname}${location.search}`;
}
